.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  margin: 0;
  background-color: #525659;
  font-family:
    Segoe UI,
    Tahoma,
    sans-serif;
}

.Example input,
.Example button {
  font: inherit;
}

.Example header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.Example header h1 {
  font-size: inherit;
  margin: 0;
}

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.Example__container__load {
  margin-top: 1em;
  color: white;
}

.Example__container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 1em 0;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}


.myContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

}